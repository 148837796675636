<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Account Manager</v-card-title>
        <v-card-text>
            <v-form ref="mainContact">
                <v-autocomplete
                    @change="deleteSearch"
                    :search-input.sync="search"
                    label="Account Manager"
                    v-model="mainContact"
                    :items="users"
                    item-text="name"
                    chips
                    item-value="id"
                    :rules="[rules.required]"
                >
                </v-autocomplete>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="secondary" @click="closeDialog">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="update" text color="error" :loading="loading">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { loadImage } from '@/helpers/imageHandler'
import API from '@/services/api'

export default {
    name: 'ChangeMainContactLeads',
    props: {
        leadId: {
            type: String,
            required: true,
            default: () => {
                return ''
            },
        },
        lead: Object,
    },
    data: () => {
        return {
            mainContact: null,
            search: '',
            loading: false,
            errMessage: '',
            companyId: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            users: [],
            rules: {
                required: v => !!v || 'Required',
            },
        }
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.mainContact = this.users.find(u => u.id == this.lead.userId)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDialog() {
            this.$emit('closeChangeMainContact')
        },
        deleteSearch() {
            this.search = ''
        },
        async update() {
            try {
                this.loading = true
                this.errMessage = ''
                const user = this.users.find(u => u.id === this.mainContact)
                if (user) {
                    if (user.picture) {
                        user.profilePicURL = await loadImage(
                            `${this.companyId}/${this.folderUsersPictures}`,
                            user.picture
                        )
                    }
                    if (!this.lead.collaborators.includes(this.mainContact)) {
                        this.lead.collaborators.push(this.mainContact)
                    }

                    this.lead.collaborators = this.lead.collaborators.filter(
                        collaborator => collaborator != this.lead.userId
                    )
                    await API.updateLead({
                        id: this.leadId,
                        userId: this.mainContact,
                        accountManager: user.name,
                        collaborators: this.lead.collaborators,
                    })
                    this.$emit('replaceMainContact', user)
                    this.$emit('closeChangeMainContact')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
